<template>
  <div>
    <el-button type="danger" class="button_delete" @click="deleteMessages"
      >删除</el-button
    >
    <el-table
      :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
      :data="messageData"
      stripe
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="createTime" label="时间" width="180">
        <template slot-scope="scope">
          {{ scope.row.createTime | formattingDate }}
        </template>
      </el-table-column>
      <el-table-column prop="messageContent" label="消息内容">
      </el-table-column>
      <el-table-column prop="address" width="180" label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            class="button_meassge"
            v-show="scope.row.status == 1"
            >已读</el-button
          >
          <el-button
            size="mini"
            class="button_unreadMeassge"
            v-show="scope.row.status == 2"
            @click="editMessages(scope.row)"
            >未读</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="app-page-container">
      <span @click="first()">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="pageSize"
        :current-page.sync="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last()">尾页</span>
    </div>
  </div>
</template>

<script>
import { getMessage, editMessage, deleteMessage } from "@/api/messageList.js";
export default {
  name: "index",
  data() {
    return {
      messageList: [],
      pageSize: 12,
      pages: 1,
      recordCount: 1,
      currentPage: 1,
      messageData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄"
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄"
        }
      ]
    };
  },
  created() {
    this.getMessages();
  },
  methods: {
    //删除消息
    deleteMessages() {
      let data = {
        messageId: []
      };
      for (let i = 0; i < this.messageList.length; i++) {
        data.messageId.push(this.messageList[i].messageId);
      }
      deleteMessage(data).then(res => {
        console.log(res);
        this.getMessages();
      });
    },
    //编辑消息
    editMessages(data) {
      data.status = 1;
      editMessage(data).then(res => {
        console.log(res);
      });
    },
    //获取消息列表
    getMessages() {
      let data = {
        limit: 12,
        curPage: this.pages
      };
      getMessage(data).then(res => {
        this.messageData = res.data.data.list;
        this.recordCount = res.data.data.total;
        console.log(res);
      });
    },
    //全选
    handleSelectionChange(val) {
      this.messageList = val;
      console.log(this.messageList);
    },
    //分页首页
    first() {
      this.currentPage = 1;
      this.pages = 1;
      this.getMessages();
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.getMessages();
    },
    handleCurrentChange(val) {
      this.pages = val;
      console.log(`当前页: ${val}`);
    }
  }
};
</script>

<style scoped>
/deep/.el-table th > .cell {
  text-align: center;
}

/deep/.el-table .cell {
  text-align: center;
}
.button_delete {
  margin-bottom: 20px;
}
.button_meassge {
  border: 1px solid #00cccc;
  border-radius: 3px;
  border-radius: 3px;
  color: #00cccc;
}
.button_unreadMeassge {
  border: 1px solid #3a3a3a;
  border-radius: 3px;
  border-radius: 3px;
  color: #3a3a3a;
}
.button_unreadMeassge:hover {
  border: 1px solid #3a3a3a;
  border-radius: 3px;
  border-radius: 3px;
  color: #3a3a3a;
  background: #ffffff;
}
.button_unreadMeassge:focus {
  border: 1px solid #3a3a3a;
  border-radius: 3px;
  border-radius: 3px;
  color: #3a3a3a;
  background: #ffffff;
}
</style>
