import axios from "../utils/request";

//获取消息列表
export function getMessage(data) {
  return axios({
    url: "/clodchainAM/web/message/getMessage",
    method: "get",
    params: data
  });
}

//修改消息
export function editMessage(data) {
  return axios({
    url: "/clodchainAM/web/message/updateMessage",
    method: "post",
    data: data
  });
}

//删除
export function deleteMessage(data) {
  return axios({
    url: "/clodchainAM/web/message/delMessage",
    method: "post",
    data: data
  });
}
